<template>
  <div class="max-w-lg w-full mx-auto h-screen">
    <h1 class="text-2xl font-bold mb-4">Tambah Sampah</h1>
    <form @submit.prevent="handleSubmit" class="flex flex-col gap-4">
      <div class="form-control">
        <label class="label">
          <span class="label-text">Upload Image</span>
        </label>
        <input type="file" @change="handleFileUpload" class="file-input file-input-bordered" />
      </div>
      <div v-if="imgPreview" class="mb-4">
        <img :src="imgPreview" alt="Image Preview" class="w-full" />
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Nama Sampah</span>
        </label>
        <input type="text" v-model="nama" class="input input-bordered" placeholder="Nama Sampah" />
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Total Sampah (kg)</span>
        </label>
        <input type="number" v-model="total" class="input input-bordered" placeholder="Total Sampah" />
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Nilai Poin (per kg)</span>
        </label>
        <input type="number" v-model="poin" class="input input-bordered" placeholder="Nilai Poin" />
      </div>
      <div class="flex gap-4">
        <button type="submit" class="btn btn-primary">Simpan</button>
        <button type="button" @click="goBack" class="btn btn-secondary">Kembali</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'AdminTambahSampah',
  setup() {
    const nama = ref('');
    const poin = ref(0);
    const total = ref(0);
    const imgPreview = ref('');
    const imageFile = ref(null);
    const router = useRouter();
    const apiUrl = process.env.VUE_APP_API_URL;


    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          imgPreview.value = e.target.result;
        };
        reader.readAsDataURL(file);
        imageFile.value = file;
      }
    };

    const handleSubmit = async () => {
      const formData = new FormData();
      formData.append('nama_sampah', nama.value || '');
      if (imageFile.value) {
        formData.append('img_sampah', imageFile.value);
      }
      formData.append('total_sampah', total.value || 0);
      formData.append('point', poin.value || 0);

      try {
        const response = await fetch(apiUrl+'/sampah', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          alert('Sampah berhasil ditambahkan!');
          router.push('/admin/sampah');
        } else {
          alert('Gagal menambahkan sampah.');
        }
      } catch (error) {
        console.error('Error adding sampah:', error);
        alert('Error adding sampah.');
      }
    };

    const goBack = () => {
      router.push('/admin/sampah');
    };

    return {
      nama,
      poin,
      total,
      imgPreview,
      handleFileUpload,
      handleSubmit,
      goBack,
    };
  },
};
</script>
