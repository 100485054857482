<template>
  <div class="w-full max-w-lg h-screen mx-auto">
    <h1 class="text-2xl font-bold mb-4">Edit Kelurahan</h1>
    <form @submit.prevent="handleSubmit" class="space-y-4">
      <div class="form-control">
        <label class="label">
          <span class="label-text">Kode Kelurahan</span>
        </label>
        <input
          v-model="kodeDesa"
          type="text"
          class="input input-bordered w-full"
          disabled />
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Nama Kelurahan</span>
        </label>
        <input
          v-model="namaDesa"
          type="text"
          class="input input-bordered w-full" />
      </div>
      <div class="flex gap-4">
        <button type="submit" class="btn btn-primary">Simpan</button>
        <button type="button" @click="goBack" class="btn btn-secondary">Kembali</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'AdminEditDesa',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const namaDesa = ref('');
    const kodeDesa = ref('');
    const desaId = ref('');
    const apiUrl = process.env.VUE_APP_API_URL;

    onMounted(async () => {
      desaId.value = route.params.id;
      try {
        const response = await fetch(`${apiUrl}/desa/${desaId.value}`);
        const data = await response.json();
        
        if (response.ok) {
          namaDesa.value = data.nama_desa;
          kodeDesa.value = data.kode;
        } else {
          alert('Failed to load desa data.');
        }
      } catch (error) {
        console.error('Error fetching desa data:', error);
        alert('Error fetching desa data.');
      }
    });

    const handleSubmit = async () => {
      try {
        const response = await fetch(`${apiUrl}/desa/${desaId.value}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nama_desa: namaDesa.value,
            kode: kodeDesa.value,
          }),
        });

        if (response.ok) {
          alert('Desa updated successfully!');
          router.push('/admin/desa');
        } else {
          alert('Failed to update desa.');
        }
      } catch (error) {
        console.error('Error updating desa:', error);
        alert('Error updating desa.');
      }
    };

    const goBack = () => {
      router.push('/admin/desa');
    };

    return {
      namaDesa,
      kodeDesa,
      handleSubmit,
      goBack,
    };
  },
};
</script>
