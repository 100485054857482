<template>
  <div class="h-screen">
    <div class="flex justify-between items-center mb-4">
      <h1 class="text-left text-xl">Jenis Sampah</h1>
      <button @click="goToAddSampah" class="btn btn-primary">Tambah Sampah</button>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6 justify-items-center">
      <div class="card-wrapper w-full max-w-xs" v-for="sampah in sampahList" :key="sampah.id_sampah">
        <div v-if="sampah.id_sampah === deletingId" class="card shadow-xl h-full">
          <div class="card-body items-center text-center">
            <h2 class="card-title">Yakin?</h2>
            <p>Ingin menghapus sampah?</p>
            <div class="card-actions justify-end">
              <button @click="deleteSampah(sampah.id_sampah)" class="btn btn-error">Hapus</button>
              <button @click="cancelDelete" class="btn btn-ghost">Batal</button>
            </div>
          </div>
        </div>
        <div v-else class="card card-compact bg-base-200 shadow-xl h-full">
          <figure>
            <img :src="sampah.img_sampah || ''" alt="Sampah" />
          </figure>
          <div class="card-body">
            <h2 class="card-title">{{ sampah.nama_sampah }}</h2>
            <p class="text-left">Total Sampah: {{ sampah.total_sampah }} Kg</p>
            <p class="text-left">{{ sampah.point }} Poin/Kg</p>
            <div class="card-actions justify-end">
              <button @click="goToEditSampah(sampah.id_sampah)" class="btn btn-accent">Edit</button>
              <button @click="confirmDelete(sampah.id_sampah)" class="btn btn-error">Hapus</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'AdminSampah',
  setup() {
    const router = useRouter();
    const deletingId = ref(null);
    const sampahList = ref([]);
    const apiUrl = process.env.VUE_APP_API_URL;


    const fetchSampahList = async () => {
      try {
        const response = await fetch(apiUrl+'/sampah');
        if (response.ok) {
          sampahList.value = await response.json();
        } else {
          alert('Gagal mengambil data sampah.');
        }
      } catch (error) {
        console.error('Error fetching sampah list:', error);
        alert('Error fetching sampah list.');
      }
    };

    onMounted(() => {
      fetchSampahList();
    });

    const goToAddSampah = () => {
      router.push('/admin/sampah/tambah');
    };

    const goToEditSampah = (id) => {
      router.push(`/admin/sampah/edit/${id}`);
    };

    const confirmDelete = (id) => {
      deletingId.value = id;
    };

    const cancelDelete = () => {
      deletingId.value = null;
    };

    const deleteSampah = async (id) => {
      try {
        const response = await fetch(`${apiUrl}/sampah/${id}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          sampahList.value = sampahList.value.filter(sampah => sampah.id_sampah !== id);
          alert('Sampah berhasil dihapus!');
        } else {
          alert('Gagal menghapus sampah.');
        }
      } catch (error) {
        console.error('Error deleting sampah:', error);
        alert('Error deleting sampah.');
      }

      cancelDelete();
    };

    return {
      goToAddSampah,
      goToEditSampah,
      confirmDelete,
      cancelDelete,
      deleteSampah,
      deletingId,
      sampahList,
    };
  },
};
</script>

<style scoped>
.card-wrapper {
  height: 18rem; /* Adjust this value as necessary */
}

.card {
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.grid {
  padding: 1rem; /* Padding around the grid */
}
</style>
