<template>
  <div class="bg-base-200">
    <div class="max-w-lg w-full mx-auto min-h-screen p-4">
      <div class="flex justify-between mt-4">
        <img :src="imageSrc" alt="Bank Sampah Rejeki" class="max-h-16 my-4 mr-auto">
        <button @click="handleLogout"><i class="fa-solid fa-arrow-right-from-bracket my-auto hover:cursor-pointer scale-125 p-4"></i></button>
      </div>
      <div class="card bg-base-100 w-full text-left p-6 max-w-lg shrink-0 shadow-2xl">
        <div class="card-header mb-4">
          <h2 class="text-3xl font-bold">Hai, {{ user.nama_user }}</h2>
        </div>
        <p class="text-lg">User ID: {{ user.id_user }}</p>
        <p class="text-lg">No. Telp: {{ user.no_hp }}</p>
        <p class="text-xl mt-2 font-bold text-green-600 flex items-center">
          <i class="fa-solid fa-wallet"></i>
          <span class="highlight-points ml-2">{{ userPoints }}</span>
        </p>
      </div>
      <div class="text-left py-4 w-full max-w-lg mx-auto">
        <h1 class="font-bold">Informasi</h1>
        <button class="card bg-base-100 w-full text-left p-4 my-2 max-w-lg shadow-2xl rounded-lg" @click="router.push('/penukaran-sampah')"><strong>Tukar sampah menjadi poin</strong></button>
        <button class="card bg-base-100 w-full text-left p-4 max-w-lg shadow-2xl rounded-lg" @click="router.push('/cara-menukarkan-poin')"><strong>Cara menukar poin menjadi hadiah</strong></button>
        <button class="mt-4 btn btn-primary w-full text-left p-4 max-w-lg shadow-2xl rounded-lg" @click="router.push('/voucher')">Tukar Poin</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'UserDashboard',
  setup() {
    const router = useRouter();
    const user = ref({});
    const userPoints = ref(0);
    const imageSrc = require('@/assets/logo.png');
    const apiUrl = process.env.VUE_APP_API_URL;

    const fetchUserData = async () => {
      const userData = JSON.parse(localStorage.getItem('user'));
      if (userData) {
        try {
          const response = await fetch(`${apiUrl}/user/${userData.id_user}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          user.value = data;
          userPoints.value = data.total_point || 0;
        } catch (error) {
          console.error('Fetch error:', error);
          router.push('/'); // Redirect to login if there's an error
        }
      } else {
        router.push('/'); // Redirect to login if no user data found
      }
    };

    const handleLogout = () => {
      localStorage.removeItem('user');
      router.push('/');
    };

    onMounted(() => {
      fetchUserData();
    });

    return {
      user,
      userPoints,
      imageSrc,
      handleLogout,
      router
    };
  },
};
</script>