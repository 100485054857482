<template>
  <div class="flex items-center justify-center min-h-screen bg-base-200">
    <div class="w-full max-w-sm p-6 m-auto rounded-md shadow-md bg-base-100">
      <h2 class="text-2xl font-bold text-center mb-6">Admin Login</h2>
      <form @submit.prevent="handleLogin">
        <label class="input input-bordered flex items-center gap-2 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="h-4 w-4 opacity-70">
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
          </svg>
          <input type="text" id="username" v-model="username" class="grow" placeholder="Username" />
        </label>
        <label class="input input-bordered flex items-center gap-2 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="h-4 w-4 opacity-70">
            <path fill-rule="evenodd" d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z" clip-rule="evenodd" />
          </svg>
          <input type="password" id="password" v-model="password" class="grow" placeholder="Password" />
        </label>
        <div v-if="message" :class="isError ? 'alert alert-error' : 'alert alert-success'" role="alert">
          <svg v-if="isError" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0 stroke-current" fill="none" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0 stroke-current" fill="none" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          <span>{{ message }}</span>
        </div>
        <div class="mt-6">
          <button type="submit" class="btn btn-primary w-full">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'LoginAdmin',
  setup() {
    const username = ref('');
    const password = ref('');
    const isError = ref(false);
    const message = ref('');
    const router = useRouter();
    const apiUrl = process.env.VUE_APP_API_URL;


    const handleLogin = async () => {
      try {
        const response = await fetch(apiUrl+'/auth/admin/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: username.value,
            password: password.value
          })
        });
        const data = await response.json();
        if (data.message === 'Admin login successful') {
          localStorage.setItem('adminLoggedIn', 'true'); // Set login status
          router.push('/admin/users'); // Redirect to admin dashboard
        }else{
          isError.value = true;
          message.value = 'Username atau password salah';
        }
      } catch (error) {
        console.error('Login failed:', error);
      }
    };


    return {
      username,
      password,
      isError,
      message,
      handleLogin,
    };
  },
};
</script>
