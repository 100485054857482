<template>
  <div class="h-screen">
    <!-- Header and Search -->
    <div class="flex flex-col lg:flex-row lg:justify-between items-center mb-4 gap-4">
      <div class="flex items-center gap-4 w-full lg:w-auto">
        <h1 class="text-left text-lg">Daftar Kelurahan</h1>
        <button @click="goToAddDesa" class="btn btn-primary">Tambah Kelurahan</button>
      </div>
      <label class="input input-bordered flex items-center gap-2 w-full lg:w-64 mt-4 lg:mt-0">
        <input type="text" v-model="searchQuery" @input="fetchDesa" class="grow" placeholder="Search" />
      </label>
    </div>

    <!-- Desa Table -->
    <div class="overflow-x-auto">
      <table class="table w-full">
        <!-- head -->
        <thead>
          <tr>
            <th>Nama Kelurahan</th>
            <th>Kode Kelurahan</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <!-- Iterasi data desa -->
          <tr v-for="desa in filteredDesaList" :key="desa.id_desa">
            <td>{{ desa.nama_desa }}</td>
            <td>{{ desa.kode }}</td>
            <td>
              <button @click="goToEditDesa(desa.id_desa)" class="btn btn-accent btn-sm mr-2">
                <i class="fas fa-edit"></i>
              </button>
              <button @click="confirmDelete(desa.id_desa)" class="btn btn-error btn-sm">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination Controls -->
    <div class="flex justify-between items-center my-4">
      <button 
        @click="prevPage" 
        :disabled="pagination.currentPage === 1" 
        class="btn btn-primary btn-sm"
      >
        Prev
      </button>
      <span>Page {{ pagination.currentPage }} of {{ pagination.totalPages }}</span>
      <button 
        @click="nextPage" 
        :disabled="pagination.currentPage === pagination.totalPages" 
        class="btn btn-primary btn-sm"
      >
        Next
      </button>
    </div>

    <!-- Konfirmasi Penghapusan -->
    <div v-if="showConfirm" role="alert" class="alert fixed top-0 left-0 right-0 mt-4 mx-auto max-w-lg border-0 bg-transparent animate__animated animate__bounceInDown">
      <div class="flex items-center bg-white text-black dark:bg-dark-bg dark:text-dark-text p-4 rounded-lg shadow-lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="stroke-info h-6 w-6 shrink-0 mr-2">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
        <span class="flex-grow mr-4">Yakin ingin menghapus kelurahan?</span>
        <div class="flex gap-2">
          <button @click="cancelDelete" class="btn btn-sm">Batal</button>
          <button @click="deleteDesa" class="btn btn-sm btn-error">Ya</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import debounce from 'lodash/debounce';

export default {
  name: 'AdminDesa',
  setup() {
    const router = useRouter();
    const showConfirm = ref(false);
    const desaIdToDelete = ref(null);
    const desaList = ref([]);
    const searchQuery = ref('');
    const pagination = ref({
      currentPage: 1,
      totalPages: 1,
      limit: 15
    });
    const apiUrl = process.env.VUE_APP_API_URL;

    // Fetch data from API with pagination and optional search
    const fetchDesa = async (page = pagination.value.currentPage, search = searchQuery.value) => {
      try {
        const response = await fetch(`${apiUrl}/desa?page=${page}&limit=${pagination.value.limit}&search=${search}`);
        const data = await response.json();
        desaList.value = data.data;
        pagination.value = {
          currentPage: data.page,
          totalPages: data.totalPages,
          limit: data.limit
        };
      } catch (error) {
        console.error('Error fetching desa data:', error);
      }
    };

    // Debounced version of fetchDesa
    const debouncedFetchDesa = debounce(fetchDesa, 300);

    // Watch for searchQuery changes to trigger a fetch
    watch(searchQuery, () => {
      pagination.value.currentPage = 1; // Reset to first page on search
      debouncedFetchDesa();
    });

    // Filter desaList based on search query
    const filteredDesaList = computed(() => {
      const query = searchQuery.value.toLowerCase();
      return desaList.value.filter(desa =>
        desa.nama_desa.toLowerCase().includes(query) ||
        desa.kode.toLowerCase().includes(query)
      );
    });

    // Go to add desa page
    const goToAddDesa = () => {
      router.push('/admin/desa/tambah');
    };

    // Go to edit desa page
    const goToEditDesa = (id) => {
      router.push(`/admin/desa/edit/${id}`);
    };

    // Show delete confirmation
    const confirmDelete = (id) => {
      desaIdToDelete.value = id;
      showConfirm.value = true;
    };

    // Cancel delete action
    const cancelDelete = () => {
      desaIdToDelete.value = null;
      showConfirm.value = false;
    };

    // Delete desa from API
    const deleteDesa = async () => {
      try {
        const response = await fetch(`${apiUrl}/desa/${desaIdToDelete.value}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          fetchDesa(pagination.value.currentPage); // Refresh the desa list
        } else {
          alert('Failed to delete desa.');
        }
      } catch (error) {
        console.error('Error deleting desa:', error);
        alert('Error deleting desa.');
      } finally {
        cancelDelete();
      }
    };

    // Change page to the next
    const nextPage = () => {
      if (pagination.value.currentPage < pagination.value.totalPages) {
        fetchDesa(pagination.value.currentPage + 1);
      }
    };

    // Change page to the previous
    const prevPage = () => {
      if (pagination.value.currentPage > 1) {
        fetchDesa(pagination.value.currentPage - 1);
      }
    };

    // Fetch data on component mount
    onMounted(() => {
      fetchDesa();
    });

    return {
      goToAddDesa,
      goToEditDesa,
      confirmDelete,
      cancelDelete,
      deleteDesa,
      showConfirm,
      filteredDesaList,
      searchQuery,
      pagination,
      nextPage,
      prevPage,
    };
  }
};
</script>

<style scoped>
.table {
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  padding: 0.5rem;
  text-align: left;
}

.table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.overflow-x-auto {
  overflow-x: auto;
}

.flex-1 {
  flex: 1;
}
</style>
