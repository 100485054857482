<template>
  <div class="h-screen w-full max-w-lg mx-auto">
    <h1 class="text-2xl font-bold mb-4">Edit Sampah</h1>
    <form @submit.prevent="handleSubmit" class="flex flex-col gap-4">
      <div class="form-control">
        <label class="label">
          <span class="label-text">Upload Image</span>
        </label>
        <input type="file" @change="handleFileUpload" class="file-input file-input-bordered" />
      </div>
      <div v-if="imgPreview" class="mb-4">
        <img :src="imgPreview" alt="Gambar tidak diubah" class="w-full" />
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Nama Sampah</span>
        </label>
        <input type="text" v-model="nama" class="input input-bordered" placeholder="Nama Sampah" />
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Total Sampah (kg)</span>
        </label>
        <input type="number" v-model="totalSampah" class="input input-bordered" placeholder="Total Sampah" />
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Nilai Poin (per kg)</span>
        </label>
        <input type="number" v-model="nilaiPoin" class="input input-bordered" placeholder="Nilai Poin" />
      </div>
      <div class="flex gap-4">
        <button type="submit" class="btn btn-primary">Simpan</button>
        <button type="button" @click="goBack" class="btn btn-secondary">Kembali</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'AdminEditSampah',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const nama = ref('');
    const totalSampah = ref(0);
    const nilaiPoin = ref(0);
    const imgPreview = ref('');
    const imageFile = ref(null);
    const apiUrl = process.env.VUE_APP_API_URL;


    const fetchSampah = async () => {
      const id = route.params.id;
      try {
        const response = await fetch(`${apiUrl}/sampah/${id}`);
        if (response.ok) {
          const sampah = await response.json();
          nama.value = sampah.nama_sampah;
          totalSampah.value = sampah.total_sampah;
          nilaiPoin.value = sampah.point;
          imgPreview.value = sampah.img_sampah ? `${sampah.img_sampah}` : '';
        } else {
          alert('Gagal mengambil data sampah.');
        }
      } catch (error) {
        console.error('Error fetching sampah:', error);
        alert('Error fetching sampah.');
      }
    };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      
      reader.onload = (e) => {
        imgPreview.value = e.target.result;
      };
      
      reader.readAsDataURL(file);
      imageFile.value = file;
    };

    const handleSubmit = async () => {
      const id = route.params.id;

      // Build the JSON object instead of FormData
      const formData = new FormData();
      formData.append('nama_sampah', nama.value || '');
      if (imageFile.value) {
        formData.append('img_sampah', imageFile.value);
      }
      formData.append('total_sampah', totalSampah.value || 0);
      formData.append('point', nilaiPoin.value || 0);

      // const formData = {
      //   nama_sampah: nama.value || '',
      //   total_sampah: totalSampah.value || 0,
      //   point: nilaiPoin.value || 0,
      //   img_sampah: imageFile.value ? imageFile.value : null // Assuming you're storing image as base64 string
      // };

      try {
        const response = await fetch(`${apiUrl}/sampah/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          alert('Sampah berhasil diperbarui!');
          router.push('/admin/sampah');
        } else {
          alert('Gagal memperbarui sampah.');
        }
      } catch (error) {
        console.error('Error updating sampah:', error);
        alert('Error updating sampah.');
      }
    };


    const goBack = () => {
      router.push('/admin/sampah');
    };

    onMounted(() => {
      fetchSampah();
    });

    return {
      nama,
      totalSampah,
      nilaiPoin,
      imgPreview,
      handleFileUpload,
      handleSubmit,
      goBack,
    };
  },
};
</script>
