<template>
    <div class="max-w-lg w-full mx-auto h-screen">
      <h1 class="text-2xl font-bold mb-4">Tambah UMKM</h1>
      <form @submit.prevent="handleSubmit">
        <div class="form-control">
            <label class="label">
            <span class="label-text">Upload Foto</span>
            </label>
            <input type="file" @change="handleFileUpload" class="file-input file-input-bordered" />
        </div>
        <div v-if="imgPreview" class="mb-4">
            <img :src="imgPreview" alt="Image Preview" class="w-full" />
        </div>
        <div class="mb-4">
          <label class="label">Kelurahan</label>
          <select v-model="desa" class="input input-bordered w-full" @change="fetchDesaKode">
            <option disabled value="">Pilih Kelurahan</option>
            <option v-for="desaItem in desaOptions" :key="desaItem.id" :value="desaItem">
              {{ desaItem.nama_desa }}
            </option>
          </select>
        </div>
        <div class="mb-4">
          <label class="label">Alamat</label>
          <input v-model="alamat" type="text" class="input input-bordered w-full" />
        </div>
        <div class="mb-4">
          <label class="label">Nama UMKM</label>
          <input v-model="nama_umkm" type="text" class="input input-bordered w-full" />
        </div>
        <div class="mb-4">
          <label class="label">Nama Pemilik</label>
          <input v-model="nama_pemilik" type="text" class="input input-bordered w-full" />
        </div>
        <div class="mb-4">
          <label class="label">Nomor HP</label>
          <input v-model="nomorHP" type="text" class="input input-bordered w-full" />
        </div>
        <div class="mb-4">
          <label class="label">Password</label>
          <input v-model="password" type="text" class="input input-bordered w-full" />
        </div>
        <div class="flex gap-4">
          <button type="submit" class="btn btn-primary">Simpan</button>
          <button type="button" @click="goBack" class="btn btn-secondary">Kembali</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'AdminTambahUMKM',
    setup() {
      const desa = ref('');
      const alamat = ref('');
      const nama_umkm = ref('');
      const nama_pemilik = ref('');
      const imgPreview = ref('');
      const imageFile = ref(null);
      const nomorHP = ref('');
      const password = ref('');
      const desaOptions = ref([]);
      const router = useRouter();
      const apiUrl = process.env.VUE_APP_API_URL;

      const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
            imgPreview.value = e.target.result;
            };
            reader.readAsDataURL(file);
            imageFile.value = file;
        }
     };
  
      // Fetch desa options when component is mounted
      onMounted(async () => {
        try {
          const response = await fetch(apiUrl + '/desa');
          const data = await response.json();
          desaOptions.value = data.data.map(desa => ({
            id: desa.id_desa,
            nama_desa: desa.nama_desa,
            kode: desa.kode,
          }));
        } catch (error) {
          console.error('Error fetching desa options:', error);
        }
      });
  
      const fetchDesaKode = () => {
        // This function can be used to update UI based on selected desa if needed
      };
  
      const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('password', password.value || '');
        formData.append('nama_umkm', nama_umkm.value || '');
        formData.append('nama_pemilik', nama_pemilik.value || '');
        formData.append('no_hp', nomorHP.value || 0);
        formData.append('desa', desa.value.nama_desa || '');
        formData.append('alamat', alamat.value || '');
        if (imageFile.value) {
            formData.append('foto', imageFile.value);
        }

        try {
          const response = await fetch(apiUrl + '/umkm', {
            method: 'POST',
            body: formData,
          });
  
          if (response.ok) {
            alert('User berhasil ditambahkan!');
            router.push('/admin/umkm');
          } else {
            alert('Gagal menambahkan umkm.');
          }
        } catch (error) {
          console.error('Error adding umkm:', error);
          alert('Error adding umkm.');
        }
      };
  
      const goBack = () => {
        router.push('/admin/umkm');
      };
  
      return {
        desa,
        alamat,
        nama_umkm,
        nama_pemilik,
        imgPreview,
        imageFile,
        handleFileUpload,
        nomorHP,
        password,
        desaOptions,
        fetchDesaKode,
        handleSubmit,
        goBack,
      };
    },
  };
  </script>
  