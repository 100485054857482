<template>
  <div class="bg-base-200">
    <div class="max-w-6xl mx-auto p-4 text-left h-screen">
        <h1 class="font-semibold text-2xl my-6" >Tukarkan Poin Sampah Anda</h1>
        <p class="mb-4" >Kumpulkan poin dengan cara mengumpulkan sampah sesuai dengan jenis-jenisnya. Tukarkan poin yang Anda dapatkan untuk berbelanja di UMKM berikut ini.</p>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6 justify-items-center">
      <!-- Card Hadiah -->
          <div class="card-wrapper w-full max-w-xs" v-for="umkm in umkmList" :key="umkm.id_umkm">
            <div class="card card-compact bg-base-100 shadow-xl h-full">
              <figure>
                <img
                  :src="umkm.foto || 'https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp'"
                  alt="UMKM"
                  class="object-cover"
                />
              </figure>
              <div class="card-body">
                <h2 class="card-title">{{ umkm.nama_umkm }}</h2>
                <div class="flex"><i class="fa-solid fa-phone"></i><p class="text-left ml-2">{{ umkm.no_hp }}</p></div>
                <div class="flex"><i class="fa-solid fa-location-dot"></i><p class="text-left ml-2">{{ umkm.alamat }}</p></div>
                
              </div>
            </div>
          </div>
        </div>
    <h2 class="font-semibold text-2xl my-6">Cara Menukarkan Poin</h2>
      <p class="mb-4">Anda dapat menukarkan poin dengan cara:</p>
      <ol>
        <li>1. Masuk ke menu tukar poin</li>
        <li>2. Tambahkan atau buat voucher belanja untuk digunakan membeli di UMKM yang tersedia</li>
        <li>3. UMKM akan melakukan scan pada QR Code voucher</li>
        <li>4. Penukaran poin menjadi barang UMKM berhasil</li>
      </ol>
      <br>
      <p>Catatan: Voucher yang tidak ditukarkan selama lebih dari 24 jam akan hangus, dan kembali menjadi poin.</p>
    </div>
  </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';

  export default {
    setup() {
      const umkmList = ref([]);
      const apiUrl = process.env.VUE_APP_API_URL;

      onMounted(async () => {
        try {
          const response = await fetch(apiUrl+'/umkm');
          if (response.ok) {
            const data = await response.json();
            umkmList.value = data.data
          } else {
            console.error('Failed to fetch umkm');
          }
        } catch (error) {
          console.error('Error fetching umkm:', error);
        }
      });

      return {
        umkmList,
      };
    },
  };
  </script>
  
  <style scoped>
.card-wrapper {
  height: 18rem; /* Adjust this value as necessary */
}

.card {
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.grid {
  padding: 1rem; /* Padding around the grid */
}
</style>

  