<template>
  <div class="bg-base-200">
  <div class="max-w-6xl mx-auto p-4 text-left h-screen">
      <h1 class="font-semibold text-2xl my-6" >Harga sampah berdasarkan jenis-jenisnya</h1>
      <p class="mb-4" >Kumpulkan poin dengan cara mengumpulkan sampah sesuai dengan jenis-jenisnya.</p>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6 justify-items-center">
      <div class="card-wrapper w-full max-w-xs" v-for="sampah in sampahList" :key="sampah.id_sampah">
        <div class="card card-compact bg-base-100 shadow-xl h-full">
          <figure>
            <img :src="sampah.img_sampah || ''" alt="Sampah" />
          </figure>
          <div class="card-body">
            <h2 class="card-title">{{ sampah.nama_sampah }}</h2>
            <p class="text-left">{{ sampah.point }} Poin/Kg</p>
            
          </div>
        </div>
      </div>
    </div>
  <h2 class="font-semibold text-2xl my-6">Cara Menukarkan Sampah Menjadi Poin</h2>
    <p class="mb-4">Anda dapat mengumpulkan poin dengan cara:</p>
    <ol>
      <li>1. Datang ke kantor Bank Sampah Rejeki dengan membawa sampah.</li>
      <li>2. Petugas akan menghitung jumlah poin yang didapat.</li>
      <li>3. Anda akan diminta nomor telepon / ID User dan nama untuk verifikasi, atau bisa juga menunjukan tampilan akun pada smartphone Anda.</li>
      <li>4. Poin akan masuk ke akun anda</li>
    </ol>
  </div>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  
  setup() {
    const sampahList = ref([]);
    const apiUrl = process.env.VUE_APP_API_URL;

    const fetchSampahList = async () => {
      try {
        const response = await fetch(apiUrl+'/sampah');
        if (response.ok) {
          sampahList.value = await response.json();
        } else {
          alert('Gagal mengambil data sampah.');
        }
      } catch (error) {
        console.error('Error fetching sampah list:', error);
        alert('Error fetching sampah list.');
      }
    };

    onMounted(() => {
      fetchSampahList();
    });  

    return {
      sampahList,
    };
  },
};
</script>


<style scoped>
.card-wrapper {
  height: 18rem; /* Adjust this value as necessary */
}

.card {
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.grid {
  padding: 1rem; /* Padding around the grid */
}
</style>
