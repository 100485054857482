<template>
    <div class="flex items-center justify-center min-h-screen bg-gray-100">
      <div class="text-center">
        <h1 class="text-9xl font-bold text-primary">404</h1>
        <p class="text-2xl font-semibold text-gray-800 mt-4">Halaman tidak tidak ada</p>
        <p class="text-gray-600 mt-2">Halaman yang kamu tuju tidak ditemukan atau mungkin sudah dipindahkan.</p>
        
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "NotFound",
  };
  </script>
    