<template>
  <div class="h-screen">
    <!-- Header and Search -->
    <div class="flex flex-col lg:flex-row lg:justify-between items-center mb-4 gap-4">
      <div class="flex items-center gap-4 w-full lg:w-auto">
        <h1 class="text-left text-lg">Transaksi Sampah</h1>
        <button @click="goToAddTransaksi" class="btn btn-primary">Buat Transaksi</button>
      </div>
      <label class="input input-bordered flex items-center gap-2 w-full lg:w-64 mt-4 lg:mt-0">
        <input
          type="text"
          v-model="searchQuery"
          @input="handleSearch"
          class="grow"
          placeholder="Search"
        />
      </label>
    </div>

    <!-- Transaksi Sampah Table -->
    <div class="overflow-x-auto">
      <table class="table w-full">
        <!-- head -->
        <thead>
          <tr>
            <th>ID User</th>
            <th>Nama</th>
            <th>Jenis Sampah</th>
            <th>Berat</th>
            <th>Total Poin</th>
            <th>Waktu</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaksi in transaksiList" :key="transaksi.id">
            <td>{{ transaksi.id_user }}</td>
            <td>{{ transaksi.nama_user }}</td>
            <td>{{ transaksi.nama_sampah }}</td>
            <td>{{ transaksi.jumlah_sampah }} Kg</td>
            <td>{{ transaksi.point }}</td>
            <td>{{ formatDate(transaksi.tgl_transaksi) }}</td>
          </tr>
          <tr v-if="transaksiList.length === 0">
            <td colspan="6" class="text-center">Tidak ada data</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination Controls -->
    <div class="flex justify-between items-center my-4">
      <button 
        @click="prevPage" 
        :disabled="pagination.currentPage === 1" 
        class="btn btn-primary btn-sm"
      >
        Prev
      </button>
      <span>Page {{ pagination.currentPage }} of {{ pagination.totalPages }}</span>
      <button 
        @click="nextPage" 
        :disabled="pagination.currentPage === pagination.totalPages" 
        class="btn btn-primary btn-sm"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'AdminTransaksiSampah',
  setup() {
    const router = useRouter();
    const transaksiList = ref([]);
    const searchQuery = ref('');
    const pagination = ref({
      currentPage: 1,
      totalPages: 1,
      limit: 15
    });
    const apiUrl = process.env.VUE_APP_API_URL;

    const fetchTransaksi = async (page = pagination.value.currentPage) => {
      try {
        const response = await fetch(`${apiUrl}/transaksi_sampah?page=${page}&limit=${pagination.value.limit}&search=${searchQuery.value}`);
        if (response.ok) {
          const data = await response.json();
          transaksiList.value = data.data;
          pagination.value = {
            currentPage: data.page,
            totalPages: data.totalPages,
            limit: data.limit
          };
        } else {
          console.error('Failed to fetch transaksi');
        }
      } catch (error) {
        console.error('Error fetching transaksi:', error);
      }
    };

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC' };
      return new Intl.DateTimeFormat('id-ID', options).format(new Date(dateString));
    };

    const nextPage = () => {
      if (pagination.value.currentPage < pagination.value.totalPages) {
        fetchTransaksi(pagination.value.currentPage + 1);
      }
    };

    const prevPage = () => {
      if (pagination.value.currentPage > 1) {
        fetchTransaksi(pagination.value.currentPage - 1);
      }
    };

    const goToAddTransaksi = () => {
      router.push('/admin/transaksi-sampah/tambah');
    };

    const handleSearch = () => {
      pagination.value.currentPage = 1; // Reset to the first page when searching
      fetchTransaksi();
    };

    onMounted(() => {
      fetchTransaksi();
    });

    watch(searchQuery, () => {
      handleSearch();
    });

    return {
      transaksiList,
      searchQuery,
      goToAddTransaksi,
      formatDate,
      pagination,
      nextPage,
      prevPage,
      handleSearch
    };
  },
};
</script>

<style scoped>
.table {
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  padding: 0.5rem;
  text-align: left;
}

.table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.overflow-x-auto {
  overflow-x: auto;
}

.flex-1 {
  flex: 1;
}
</style>
