<template>
  <div class="h-screen flex flex-col">
    <!-- Header and Search -->
    <div class="flex flex-col lg:flex-row lg:justify-between items-center mb-4 gap-4">
      <div class="flex items-center gap-4 w-full lg:w-auto">
        <h1 class="text-left text-lg">Daftar Warga</h1>
        <button @click="goToAddUser" class="btn btn-primary">Tambah User</button>
      </div>
      <label class="input input-bordered flex items-center gap-2 w-full lg:w-64 mt-4 lg:mt-0">
        <input 
          type="text" 
          v-model="searchQuery" 
          @input="fetchUsers" 
          class="grow" 
          placeholder="Cari berdasarkan nama" 
        />
      </label>
    </div>

    <!-- User Table -->
    <div class="overflow-x-auto flex-1">
      <table class="table w-full">
        <!-- head -->
        <thead>
          <tr>
            <th>ID User</th>
            <th>Nama</th>
            <th>Kelurahan</th>
            <th>Nomor HP</th>
            <th>Poin</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in filteredUsers" :key="user.id_user">
            <td>{{ user.id_user }}</td>
            <td>{{ user.nama_user }}</td>
            <td>{{ user.desa }}</td>
            <td>{{ user.no_hp }}</td>
            <td>{{ user.total_point }}</td>
            <td>
              <!-- Show QR Code Button -->
              <button @click="showQRCode(user.qr_code)" class="btn btn-secondary btn-sm mr-2">
                <i class="fas fa-qrcode"></i>
              </button>
              <!-- Edit User Button -->
              <button @click="goToEditUser(user.id_user)" class="btn btn-accent btn-sm mr-2">
                <i class="fas fa-edit"></i>
              </button>
              <!-- Delete User Button -->
              <button @click="confirmDelete(user.id_user)" class="btn btn-error btn-sm">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination Controls -->
    <div class="flex justify-between items-center my-4">
      <button 
        @click="prevPage" 
        :disabled="pagination.currentPage === 1" 
        class="btn btn-primary btn-sm"
      >
        Prev
      </button>
      <span>Page {{ pagination.currentPage }} of {{ pagination.totalPages }}</span>
      <button 
        @click="nextPage" 
        :disabled="pagination.currentPage === pagination.totalPages" 
        class="btn btn-primary btn-sm"
      >
        Next
      </button>
    </div>

    <!-- QR Code Modal -->
    <div v-if="showQRCodeModal" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div class="bg-white p-4 rounded-lg relative">
        <button @click="showQRCodeModal = false" class="absolute top-2 right-2 text-gray-500">
          <i class="fas fa-times"></i>
        </button>
        <!-- Display QR Code Image -->
        <img :src="qrCodeUrl" alt="QR Code" style="max-width: 100%; height: auto;" />
      </div>
    </div>

    <!-- Confirmation Modal for Deletion -->
    <div v-if="showConfirm" role="alert" class="alert fixed top-0 left-0 right-0 mt-4 mx-auto max-w-lg bg-transparent border-0 animate__animated animate__bounceInDown">
      <div class="flex items-center bg-white text-black dark:bg-dark-bg dark:text-dark-text p-4 rounded-lg shadow-lg">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-info h-6 w-6 shrink-0 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
        <span class="flex-grow mr-4">Yakin ingin menghapus user?</span>
        <div class="flex gap-2">
          <button @click="cancelDelete" class="btn btn-sm">Batal</button>
          <button @click="deleteUser" class="btn btn-sm btn-error">Ya</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'AdminUsers',
  setup() {
    const router = useRouter();
    const showConfirm = ref(false);
    const showQRCodeModal = ref(false);
    const qrCodeUrl = ref('');
    const userIdToDelete = ref(null);
    const users = ref([]);
    const searchQuery = ref('');
    const pagination = ref({
      currentPage: 1,
      totalPages: 1,
      limit: 15
    });
    const apiUrl = process.env.VUE_APP_API_URL;

    // Fetch users when component is mounted or search query changes
    const fetchUsers = async (page = pagination.value.currentPage) => {
      try {
        const response = await fetch(
          `${apiUrl}/user?page=${page}&limit=${pagination.value.limit}&search=${searchQuery.value}`
        );
        const data = await response.json();
        users.value = data.data;
        pagination.value = {
          currentPage: data.page,
          totalPages: data.totalPages,
          limit: data.limit
        };
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    onMounted(() => {
      fetchUsers();
    });

    // Filter users based on search query
    const filteredUsers = computed(() => {
      const query = searchQuery.value.toLowerCase();
      return users.value.filter(user =>
        user.nama_user.toLowerCase().includes(query) ||
        user.id_user.toString().includes(query) ||
        user.no_hp.toLowerCase().includes(query) ||
        user.desa.toLowerCase().includes(query)
      );
    });

    const goToAddUser = () => {
      router.push('/admin/user/tambah');
    };

    const goToEditUser = (id) => {
      router.push(`/admin/user/edit/${id}`);
    };

    const confirmDelete = (id) => {
      userIdToDelete.value = id;
      showConfirm.value = true;
    };

    const cancelDelete = () => {
      userIdToDelete.value = null;
      showConfirm.value = false;
    };

    const deleteUser = async () => {
      try {
        const response = await fetch(`${apiUrl}/user/${userIdToDelete.value}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          fetchUsers(pagination.value.currentPage); // Refresh user list
          alert('User berhasil dihapus!');
        } else {
          alert('Gagal menghapus user.');
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Error deleting user.');
      } finally {
        cancelDelete();
      }
    };

    const showQRCode = (qrCode) => {
      qrCodeUrl.value = qrCode;
      showQRCodeModal.value = true;
    };

    const nextPage = () => {
      if (pagination.value.currentPage < pagination.value.totalPages) {
        pagination.value.currentPage++;
        fetchUsers(pagination.value.currentPage);
      }
    };

    const prevPage = () => {
      if (pagination.value.currentPage > 1) {
        pagination.value.currentPage--;
        fetchUsers(pagination.value.currentPage);
      }
    };

    return {
      goToAddUser,
      goToEditUser,
      confirmDelete,
      cancelDelete,
      deleteUser,
      showQRCode,
      showQRCodeModal,
      qrCodeUrl,
      showConfirm,
      filteredUsers,
      searchQuery,
      pagination,
      nextPage,
      prevPage,
      fetchUsers
    };
  },
};
</script>