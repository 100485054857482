<template>
  <div class="h-screen w-full max-w-lg mx-auto">
    <h1 class="text-2xl font-bold mb-4">Tambah Transaksi UMKM</h1>
    <form @submit.prevent="handleSubmit" class="flex flex-col gap-4">
      <div class="form-control">
        <label class="label">
          <span class="label-text">ID UMKM</span>
        </label>
        <input type="text" v-model="idUmkm" @input="onUmkmIdInput" list="userList" class="input input-bordered" placeholder="ID Umkm" />
        <datalist id="userList">
          <option v-for="user in users" :key="user.id_user" :value="user.id_user">
            {{ user.id_user }}
          </option>
        </datalist>
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Jumlah Poin</span>
        </label>
        <input type="number" v-model="jumlah" class="input input-bordered" placeholder="Jumlah Poin" />
      </div>
      <div class="flex gap-4">
        <button type="submit" class="btn btn-primary">Buat Transaksi</button>
        <button type="button" @click="goBack" class="btn btn-secondary">Kembali</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'AdminTambahTransaksiUmkm',
  setup() {
    const idUmkm = ref('');
    const jumlah = ref('');
    const users = ref([]);
    const router = useRouter();
    const apiUrl = process.env.VUE_APP_API_URL;

    const fetchUmkms = async () => {
      try {
        const response = await fetch(apiUrl+'/umkm');
        if (response.ok) {
          const data = await response.json();
          users.value = data.data; // Sesuaikan berdasarkan struktur respons
        } else {
          console.error('Gagal mengambil data umkm');
        }
      } catch (error) {
        console.error('Error fetching UMKM:', error);
      }
    };


    const handleSubmit = async () => {
      try {
        // Buat transaksi
        const formData = {
          id_umkm: idUmkm.value,
          point: jumlah.value,
        };

        const transactionResponse = await fetch(apiUrl+'/transaksi_tukar_point', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (!transactionResponse.ok) {
          alert('Gagal menambahkan Transaksi UMKM.');
          return;
        }else{
          alert('Transaksi UMKM Berhasil');
          router.push('/admin/transaksi-umkm');
        }

      } catch (error) {
        console.error('Error handling transaksi hadiah:', error);
        alert('Error processing transaksi hadiah.');
      }
    };

    const goBack = () => {
      router.push('/admin/transaksi-umkm');
    };

    onMounted(() => {
      fetchUmkms();
    });

    return {
      idUmkm,
      jumlah,
      users,
      handleSubmit,
      goBack,
    };
  },
};
</script>
