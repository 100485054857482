<template>
  <div class="h-screen w-full max-w-lg mx-auto">
    <h1 class="text-2xl font-bold mb-4">Tambah Transaksi Sampah</h1>
    <form @submit.prevent="handleSubmit" class="flex flex-col gap-4">
      <div class="form-control">
        <label class="label">
          <span class="label-text">ID User</span>
        </label>
        <input
          type="text"
          v-model="idUser"
          @input="onUserIdInput"
          list="userList"
          class="input input-bordered"
          placeholder="ID User"
        />
        <datalist id="userList">
          <option v-for="user in users" :key="user.id_user" :value="user.id_user">
            {{ user.id_user }}
          </option>
        </datalist>
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Nama</span>
        </label>
        <input
          type="text"
          v-model="nama"
          class="input input-bordered"
          placeholder="Nama"
          :readonly="true"
        />
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Jenis Sampah</span>
        </label>
        <select v-model="jenisSampah" class="select select-bordered">
          <option v-for="sampah in sampahList" :key="sampah.id_sampah" :value="sampah.nama_sampah">
            {{ sampah.nama_sampah }}
          </option>
        </select>
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Berat (kg)</span>
        </label>
        <input type="number" step="0.01" v-model="berat" class="input input-bordered" placeholder="Berat" />
      </div>
      <div class="flex gap-4">
        <button type="submit" class="btn btn-primary">Simpan</button>
        <button type="button" @click="goBack" class="btn btn-secondary">Kembali</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'AdminTambahTransaksiSampah',
  setup() {
    const idUser = ref('');
    const nama = ref('');
    const jenisSampah = ref('');
    const berat = ref('');
    const users = ref([]);
    const sampahList = ref([]);
    const router = useRouter();
    const apiUrl = process.env.VUE_APP_API_URL;


    const fetchUsers = async () => {
      try {
        const response = await fetch(apiUrl+'/user');
        if (response.ok) {
          const data = await response.json();
          users.value = data.data; // Adjust based on actual response structure
        } else {
          console.error('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchSampah = async () => {
      try {
        const response = await fetch(apiUrl+'/sampah');
        if (response.ok) {
          const data = await response.json();
          sampahList.value = data; // Adjust based on actual response structure
        } else {
          console.error('Failed to fetch sampah');
        }
      } catch (error) {
        console.error('Error fetching sampah:', error);
      }
    };

    const onUserIdInput = async () => {
      const user = users.value.find(u => u.id_user === idUser.value);
      if (user) {
        nama.value = user.nama_user;
      } else {
        nama.value = ''; // Clear name if no user is found
      }
    };

    const handleSubmit = async () => {
      const selectedSampah = sampahList.value.find(s => s.nama_sampah === jenisSampah.value);

      if (!selectedSampah) {
        alert('Jenis sampah tidak valid.');
        return;
      }

      const formData = {
        id_user: idUser.value,
        nama_user: nama.value,
        nama_sampah: jenisSampah.value,
        jumlah_sampah: berat.value
      };

      try {
        // Save the transaction
        const response = await fetch(apiUrl+'/transaksi_sampah', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {

          alert('Transaksi Berhasil. Poin pengguna berhasil diperbarui!');
          router.push('/admin/transaksi-sampah');


        } else {
          alert('Gagal menambahkan transaksi sampah.');
        }
      } catch (error) {
        console.error('Error adding transaksi sampah:', error);
        // alert('Error adding transaksi sampah.');
        router.push('/admin/transaksi-sampah');
      }
    };

    const goBack = () => {
      router.push('/admin/transaksi-sampah');
    };

    onMounted(() => {
      fetchUsers();
      fetchSampah();
    });

    return {
      idUser,
      nama,
      jenisSampah,
      berat,
      users,
      sampahList,
      handleSubmit,
      goBack,
      onUserIdInput
    };
  },
};
</script>
