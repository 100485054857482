import { createRouter, createWebHistory } from 'vue-router';
import LoginAdmin from './components/LoginAdmin.vue';
import AdminDashboard from './components/AdminDashboard.vue';
import AdminDesa from './components/desa/AdminDesa.vue';
import AdminUsers from './components/user/AdminUsers.vue';
import AdminTambahUser from './components/user/AdminTambahUser.vue';
import AdminEditUser from './components/user/AdminEditUser.vue';
import AdminTambahDesa from './components/desa/AdminTambahDesa.vue';
import AdminEditDesa from './components/desa/AdminEditDesa.vue';
import AdminSampah from './components/sampah/AdminSampah.vue';
import AdminTambahSampah from './components/sampah/AdminTambahSampah.vue';
import AdminEditSampah from './components/sampah/AdminEditSampah.vue';
import LoginUser from './components/user-warga/LoginUser.vue';
import UserDashboard from './components/user-warga/UserDashboard.vue';
import CaraMenukarkanPoin from './components/user-warga/CaraMenukarkanPoin.vue';
import PenukaranSampah from './components/user-warga/PenukaranSampah.vue';
import AdminUMKM from './components/umkm/AdminUMKM.vue';
import AdminTambahUMKM from './components/umkm/AdminTambahUMKM.vue';
import AdminEditUMKM from './components/umkm/AdminEditUMKM.vue';
import UmkmDashboard from './components/user-umkm/UmkmDashboard.vue';
import UmkmLogin from './components/user-umkm/UmkmLogin.vue';
import UserVoucher from './components/user-warga/UserVoucher.vue';
import AdminTransaksiUmkm from './components/transaksi/AdminTransaksiUmkm.vue';
import AdminTambahTransaksiUmkm from './components/transaksi/AdminTambahTransaksiUmkm.vue';
import AdminTransaksiSampah from './components/transaksi/AdminTransaksiSampah.vue';
import AdminTambahTransaksiSampah from './components/transaksi/AdminTambahTransaksiSampah.vue';
import NotFound from './components/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'LoginUser',
    component: LoginUser,
    meta: { title: 'Bank Sampah Rejeki' }
  },
  {
    path: '/umkm',
    name: 'UmkmLogin',
    component: UmkmLogin,
    meta: { title: 'Bank Sampah Rejeki' }
  },
  {
    path: '/dashboard',
    name: 'UserDashboard',
    component: UserDashboard,
    meta: { title: 'Dashboard Warga' }
  },
  {
    path: '/voucher',
    name: 'UserVoucher',
    component: UserVoucher,
    meta: { title: 'Voucher - Bank Sampah Rejeki' }
  },
  {
    path: '/penukaran-sampah',
    name: 'PenukaranSampah',
    component: PenukaranSampah,
    meta: { title: 'Penukaran Sampah - Bank Sampah Rejeki' }
  },
  {
    path: '/cara-menukarkan-poin',
    name: 'CaraMenukarkanPoin',
    component: CaraMenukarkanPoin,
    meta: { title: 'Cara Menukarkan Poin - Bank Sampah Rejeki' }
  },
  {
    path: '/umkm-dashboard',
    name: 'UmkmDashboard',
    component: UmkmDashboard,
    meta: { title: 'Dashboard UMKM - Bank Sampah Rejeki' }
  },
  {
    path: '/admin/login',
    name: 'LoginAdmin',
    component: LoginAdmin,
    meta: { title: 'Login Admin - Bank Sampah Rejeki' }
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: { title: 'Dashboard Admin - Bank Sampah Rejeki' },
    children: [
      { path: 'users', name: 'AdminUsers', component: AdminUsers },
      { path: 'user/tambah', name: 'AdminTambahUser', component: AdminTambahUser },
      { path: 'user/edit/:id', name: 'AdminEditUser', component: AdminEditUser },
      { path: 'desa', name: 'AdminDesa', component: AdminDesa },
      { path: 'desa/tambah', name: 'AdminTambahDesa', component: AdminTambahDesa },
      { path: 'desa/edit/:id', name: 'AdminEditDesa', component: AdminEditDesa },
      { path: 'umkm', name: 'AdminUMKM', component: AdminUMKM },
      { path: 'umkm/tambah', name: 'AdminTambahUMKM', component: AdminTambahUMKM },
      { path: 'umkm/edit/:id', name: 'AdminEditUMKM', component: AdminEditUMKM },
      { path: 'sampah', name: 'AdminSampah', component: AdminSampah },
      { path: 'sampah/tambah', name: 'AdminTambahSampah', component: AdminTambahSampah },
      { path: 'sampah/edit/:id', name: 'AdminEditSampah', component: AdminEditSampah },
      { path: 'transaksi-sampah', name: 'AdminTransaksiSampah', component: AdminTransaksiSampah },
      { path: 'transaksi-sampah/tambah', name: 'AdminTambahTransaksiSampah', component: AdminTambahTransaksiSampah },
      { path: 'transaksi-umkm', name: 'AdminTransaksiUmkm', component: AdminTransaksiUmkm },
      { path: 'transaksi-umkm/tambah', name: 'AdminTambahTransaksiUmkm', component: AdminTambahTransaksiUmkm },
    ]
  },
  { path: '/:pathMatch(.*)*', 
    component: NotFound,
    meta: { title: '404 - Page Not Found' }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('adminLoggedIn') === 'true';
  
  if (to.path.startsWith('/admin') && to.path !== '/admin/login' && !isLoggedIn) {
    next('/admin/login'); // Redirect to login if not logged in
  } else if (to.path === '/admin/login' && isLoggedIn) {
    next('/admin'); // Redirect to admin dashboard if already logged in
  } else {
    next(); // Allow navigation
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'Default Title'; // Title default jika tidak ada meta title
  }
});

export default router;
