<template>
  <div class="h-screen">
    <!-- Header and Search -->
    <div class="flex flex-col lg:flex-row lg:justify-between items-center mb-4 gap-4">
      <div class="flex items-center gap-4 w-full lg:w-auto">
        <h1 class="text-left text-lg">Transaksi Hadiah</h1>
        <button @click="goToAddTransaksi" class="btn btn-primary">Buat Transaksi</button>
      </div>
      <label class="input input-bordered flex items-center gap-2 w-full lg:w-64 mt-4 lg:mt-0">
        <input type="text" v-model="search" @input="handleSearch" class="grow" placeholder="Search" />
      </label>
    </div>

    <!-- Transaksi Hadiah Table -->
    <div class="overflow-x-auto">
      <table class="table w-full">
        <!-- head -->
        <thead>
          <tr>
            <th>ID UMKM</th>
            <th>Nama UMKM</th>
            <th>Total Poin</th>
            <th>Waktu</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaksi in transaksiHadiah" :key="transaksi.id">
            <td>{{ transaksi.id_umkm }}</td>
            <td>{{ transaksi.nama_umkm }}</td>
            <td>{{ transaksi.point }}</td>
            <td>{{ formatDate(transaksi.created_at) }}</td>
          </tr>
          <tr v-if="!transaksiHadiah.length">
            <td colspan="4" class="text-center">Tidak ada data</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination Controls -->
    <div class="flex justify-between items-center my-4">
      <button 
        @click="prevPage" 
        :disabled="!pagination.prev_page_url" 
        class="btn btn-primary btn-sm"
      >
        Prev
      </button>
      <span>Page {{ pagination.current_page }} of {{ pagination.last_page }}</span>
      <button 
        @click="nextPage" 
        :disabled="!pagination.next_page_url" 
        class="btn btn-primary btn-sm"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'AdminTransaksiHadiah',
  setup() {
    const router = useRouter();
    const transaksiHadiah = ref([]);
    const search = ref('');
    const pagination = ref({
      current_page: 1,
      last_page: 1,
      next_page_url: null,
      prev_page_url: null,
    });
    const apiUrl = process.env.VUE_APP_API_URL;

    const goToAddTransaksi = () => {
      router.push('/admin/transaksi-umkm/tambah');
    };

    const fetchTransaksiHadiah = async (url = `${apiUrl}/transaksi_tukar_point?page=${pagination.value.current_page}&limit=15&search=${search.value}`) => {
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          transaksiHadiah.value = data.data;
          pagination.value = {
            current_page: data.current_page,
            last_page: data.last_page,
            next_page_url: data.next_page_url,
            prev_page_url: data.prev_page_url,
          };
        } else {
          console.error('Failed to fetch transaksi hadiah');
        }
      } catch (error) {
        console.error('Error fetching transaksi hadiah:', error);
      }
    };

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC' };
      return new Intl.DateTimeFormat('id-ID', options).format(new Date(dateString));
    };

    const nextPage = () => {
      if (pagination.value.next_page_url) {
        fetchTransaksiHadiah(pagination.value.next_page_url);
      }
    };

    const prevPage = () => {
      if (pagination.value.prev_page_url) {
        fetchTransaksiHadiah(pagination.value.prev_page_url);
      }
    };

    const handleSearch = () => {
      pagination.value.current_page = 1; // Reset to the first page when searching
      fetchTransaksiHadiah();
    };

    onMounted(() => {
      fetchTransaksiHadiah();
    });

    return {
      goToAddTransaksi,
      transaksiHadiah,
      search,
      handleSearch,
      fetchTransaksiHadiah,
      formatDate,
      pagination,
      nextPage,
      prevPage
    };
  },
};
</script>
