<template>
    <div class="bg-base-200">
      <div class="max-w-lg w-full mx-auto min-h-screen p-4">
        <div class="flex justify-between mt-4">
          <img :src="imageSrc" alt="Bank Sampah Rejeki" class="max-h-16 my-4 mr-auto">
          <button @click="showVoucherPopup = true"><i class="fa-solid fa-plus my-auto hover:cursor-pointer scale-125 p-4"></i></button>
        </div>
  
        <!-- Display Total Points -->
        <div class="card bg-base-100 w-full text-left p-6 max-w-lg shrink-0 shadow-2xl">
          <h2 class="text-2xl font-bold">Total Poin Anda</h2>
          <p class="text-3xl mt-2 font-bold text-green-600 flex items-center">
            <i class="fa-solid fa-wallet"></i>
            <span class="ml-2">{{ userPoints }}</span>
          </p>
        </div>
  
        <!-- Display Voucher List -->
        <div v-if="voucherList.length" class="text-left py-4 w-full max-w-lg mx-auto">
          <h1 class="font-bold">Daftar Voucher</h1>
          <div v-for="voucher in voucherList" :key="voucher.id_voucher" class="card bg-base-100 w-full text-left p-4 my-2 max-w-lg shadow-2xl rounded-lg">
            <p>Kode: {{ voucher.code }}</p>
            <p>Nilai: {{ voucher.value }}</p>
            <p>Aktif Sampai: {{ voucher.exp_at }}</p>
            <p>Status: {{ voucher.status == 1 ? 'Aktif' : 'Tidak Aktif' }}</p>
            <button class="btn btn-primary mt-2" @click="showQRCodePopup(voucher.qr_code)">Tukarkan</button>
          </div>
        </div>
      </div>
  
      <!-- Voucher Popup -->
      <div v-if="showVoucherPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="card bg-base-100 p-8 max-w-sm rounded-lg">
          <h2 class="text-2xl font-bold mb-4">Tukar Poin Menjadi Voucher</h2>
          <p>Total Poin: {{ userPoints }}</p>
          <input v-model="voucherValue" type="number" placeholder="Masukkan nilai voucher" class="input input-bordered w-full mt-4">
          <div class="flex justify-end mt-6">
            <button @click="createVoucher" class="btn btn-primary mr-2">Tambah</button>
            <button @click="showVoucherPopup = false" class="btn btn-secondary">Batal</button>
          </div>
        </div>
      </div>
  
      <!-- QR Code Popup -->
      <div v-if="showQRCode" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="card bg-base-100 p-8 max-w-sm rounded-lg">
          <h2 class="text-2xl font-bold mb-4">QR Code Voucher</h2>
          <img :src="qrCodeSrc" alt="QR Code" class="mx-auto">
          <div class="flex justify-end mt-6">
            <button @click="closeQRCodePopup" class="btn btn-primary">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'UserDashboard',
    setup() {
      const router = useRouter();
      const user = ref({});
      const userPoints = ref(0);
      const imageSrc = require('@/assets/logo.png');
      const voucherList = ref([]);
      const showVoucherPopup = ref(false);
      const voucherValue = ref(0);
      const showQRCode = ref(false);
      const qrCodeSrc = ref('');
      const apiUrl = process.env.VUE_APP_API_URL;

  
      const fetchUserData = async () => {
        const userData = JSON.parse(localStorage.getItem('user'));

        if (userData) {
          try {
            const response = await fetch(`${apiUrl}/user/${userData.id_user}`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            user.value = data;
            userPoints.value = data.total_point || 0;
            fetchVouchers(userData.id_user);
          } catch (error) {
            console.error('Fetch error:', error);
            router.push('/'); // Redirect to login if there's an error
          }
        } else {
          router.push('/'); // Redirect to login if no user data found
        }

      };
  
      const fetchVouchers = async (userId) => {
        try {
          const response = await fetch(`${apiUrl}/vouchers?search=${userId}`);
          const data = await response.json();
          voucherList.value = data.data;
        } catch (error) {
          console.error('Failed to fetch vouchers:', error);
        }
      };
  
      const createVoucher = async () => {
        if (voucherValue.value > 0 && voucherValue.value <= userPoints.value) {
            try {
            const response = await fetch(`${apiUrl}/vouchers`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify({
                id_user: user.value.id_user,
                value: voucherValue.value,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                voucherList.value.push(data); // Add the new voucher to the list
                userPoints.value -= voucherValue.value; // Deduct points from user's total
                showVoucherPopup.value = false; // Close the popup
            } else {
                const errorData = await response.json();
                alert(errorData.message || "Gagal menambah voucher");
            }
            } catch (error) {
            console.error("Error creating voucher:", error);
            alert("Terjadi kesalahan saat menambah voucher");
            }
        } else {
            alert("Nilai voucher tidak valid atau melebihi jumlah poin");
        }
        };

  
      const showQRCodePopup = (qrCode) => {
        qrCodeSrc.value = qrCode;
        showQRCode.value = true;
      };
  
      const closeQRCodePopup = () => {
        showQRCode.value = false;
      };
  
      onMounted(() => {
        fetchUserData();
      });
  
      return {
        user,
        userPoints,
        imageSrc,
        voucherList,
        showVoucherPopup,
        voucherValue,
        showQRCode,
        qrCodeSrc,
        createVoucher,
        showQRCodePopup,
        closeQRCodePopup
      };
    },
  };
  </script>
  