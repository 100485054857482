<template>
    <div class="h-screen w-full max-w-lg mx-auto">
      <h1 class="text-2xl font-bold mb-4">Edit UMKM</h1>
      <form @submit.prevent="handleSubmit" class="flex flex-col gap-4">
        <div class="form-control">
            <label class="label">
            <span class="label-text">Ganti Foto</span>
            </label>
            <input type="file" @change="handleFileUpload" class="file-input file-input-bordered" />
        </div>
        <div v-if="imgPreview" class="mb-4">
            <img :src="imgPreview" alt="Gambar Tidak Diubah" class="w-full" />
        </div>
        <div class="mb-4">
          <label class="label">Alamat</label>
          <input v-model="alamat" type="text" class="input input-bordered w-full" />
        </div>
        <div class="mb-4">
          <label class="label">Nama UMKM</label>
          <input v-model="nama_umkm" type="text" class="input input-bordered w-full" />
        </div>
        <div class="mb-4">
          <label class="label">Nama Pemilik</label>
          <input v-model="nama_pemilik" type="text" class="input input-bordered w-full" />
        </div>
        <div class="mb-4">
          <label class="label">Nomor HP</label>
          <input v-model="nomorHP" type="text" class="input input-bordered w-full" />
        </div>
        <div class="mb-4">
          <label class="label">Password</label>
          <input v-model="password" type="text" class="input input-bordered w-full" />
        </div>
        <div class="flex gap-4">
          <button type="submit" class="btn btn-primary">Simpan</button>
          <button type="button" @click="goBack" class="btn btn-secondary">Kembali</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  
  export default {
    name: 'AdminEditUMKM',
    setup() {
      const route = useRoute();
      const router = useRouter();
      const alamat = ref('');
      const nama_umkm = ref('');
      const nama_pemilik = ref('');
      const imgPreview = ref('');
      const imageFile = ref(null);
      const nomorHP = ref('');
      const password = ref('');
      const apiUrl = process.env.VUE_APP_API_URL;
  
  
      const fetchUMKM = async () => {
        const id = route.params.id;
        try {
          const response = await fetch(`${apiUrl}/umkm/${id}`);
          if (response.ok) {
            const umkm = await response.json();
            nama_umkm.value = umkm.nama_umkm;
            nama_pemilik.value = umkm.nama_pemilik;
            alamat.value = umkm.alamat;
            nomorHP.value = umkm.no_hp;
            password.value = umkm.password;
            imgPreview.value = umkm.foto ? `${umkm.foto}` : '';
          } else {
            alert('Gagal mengambil data umkm.');
          }
        } catch (error) {
          console.error('Error fetching umkm:', error);
          alert('Error fetching umkm.');
        }
      };
  
      const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        
        reader.onload = (e) => {
          imgPreview.value = e.target.result;
        };
        
        reader.readAsDataURL(file);
        imageFile.value = file;
      };
  
      const handleSubmit = async () => {
        const id = route.params.id;

        // Build the JSON object
        const payload = {
            password: password.value || '',
            nama_umkm: nama_umkm.value || '',
            nama_pemilik: nama_pemilik.value || '',
            no_hp: nomorHP.value || 0,
            alamat: alamat.value || '',
        };

        // If there's an image file, add it to the payload (converted to base64)
        if (imageFile.value) {
            const reader = new FileReader();
            reader.readAsDataURL(imageFile.value);
            reader.onloadend = async () => {
                payload.foto = reader.result;

                try {
                    const response = await fetch(`${apiUrl}/umkm/${id}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });

                    if (response.ok) {
                        alert('UMKM berhasil diperbarui!');
                        router.push('/admin/umkm');
                    } else {
                        alert('Gagal memperbarui UMKM.');
                    }
                } catch (error) {
                    console.error('Error updating UMKM:', error);
                    alert('Error updating UMKM.');
                }
            };
        } else {
            try {
                const response = await fetch(`${apiUrl}/umkm/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                if (response.ok) {
                    alert('UMKM berhasil diperbarui!');
                    router.push('/admin/umkm');
                } else {
                    alert('Gagal memperbarui UMKM.');
                }
            } catch (error) {
                console.error('Error updating UMKM:', error);
                alert('Error updating UMKM.');
            }
        }
    };

  
  
      const goBack = () => {
        router.push('/admin/umkm');
      };
  
      onMounted(() => {
        fetchUMKM();
      });
  
      return {
        nama_umkm,
        nama_pemilik,
        alamat,
        nomorHP,
        password,
        imgPreview,
        handleFileUpload,
        handleSubmit,
        goBack,
      };
    },
  };
  </script>
  