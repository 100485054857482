<template>
  <div class="bg-base-200">
    <div class="max-w-lg w-full mx-auto min-h-screen p-4">
      <div class="flex justify-between mt-4">
        <img :src="imageSrc" alt="Bank Sampah Rejeki" class="max-h-16 my-4 mr-auto">
        <button @click="handleLogout">
          <i class="fa-solid fa-arrow-right-from-bracket my-auto hover:cursor-pointer scale-125 p-4"></i>
        </button>
      </div>
      <div class="card bg-base-100 w-full text-left p-6 max-w-lg shrink-0 shadow-2xl">
        <div class="card-header mb-4">
          <h2 class="text-3xl font-bold">Hai, {{ umkm.nama_pemilik }}</h2>
        </div>
        <p class="text-lg">{{ umkm.nama_umkm }}</p>
        <p class="text-lg">No. Telp: {{ umkm.no_hp }}</p>
        <p class="text-xl mt-2 font-bold text-green-600 flex items-center">
          <i class="fa-solid fa-wallet"></i>
          <span class="highlight-points ml-2">{{ umkmPoints }}</span>
        </p>
      </div>
      <div class="text-left py-4 w-full max-w-lg mx-auto">
        <h1 class="font-bold">Informasi</h1>
        <button class="card bg-base-100 w-full text-left p-4 my-2 max-w-lg shadow-2xl rounded-lg" @click="router.push('/penukaran-sampah')">
          <strong>Tukar sampah menjadi poin</strong>
        </button>
        <button class="card bg-base-100 w-full text-left p-4 max-w-lg shadow-2xl rounded-lg" @click="router.push('/cara-menukarkan-poin')">
          <strong>Cara menukar poin menjadi hadiah</strong>
        </button>
      </div>
      <button type="button" class="btn btn-primary w-full text-left p-4 max-w-lg shadow-2xl rounded-lg" @click="startQRScan">
        <i class="fa-solid fa-qrcode"></i> Scan Voucher Warga
      </button>

      <div v-if="!cameraAccess" class="mt-4 p-4 bg-red-100 border border-red-500 text-red-700">
        <p>Untuk menggunakan fitur pemindaian QR, mohon izinkan akses kamera di pengaturan browser Anda.</p>
      </div>
      <video id="qr-video" style="width: 100%; height: 0px;"></video>
    </div>

    <!-- Voucher Information Popup -->
    <div v-if="showVoucherPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div class="card bg-base-100 p-8 max-w-sm rounded-lg">
        <h2 class="text-2xl font-bold mb-4">Voucher Information</h2>
        <p><strong>Kode:</strong> {{ voucherInfo.code }}</p>
        <p><strong>Nilai:</strong> {{ voucherInfo.value }}</p>
        <p><strong>Kadaluarsa:</strong> {{ voucherInfo.exp_at }}</p>
        <p><strong>Status:</strong> {{ voucherInfo.status == 1 ? 'Aktif' : 'Tidak Aktif' }}</p>
        <input v-model="redeemValue" type="number" placeholder="Point untuk ditukar" class="input input-bordered w-full mt-4" value="{{ voucherInfo.value }}" />
        <div class="flex justify-end mt-6">
          <button @click="redeemVoucher" class="btn btn-primary mr-2">Tukar</button>
          <button @click="showVoucherPopup = false" class="btn btn-secondary">Batal</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import QrScanner from 'qr-scanner';

export default {
  name: 'UmkmDashboard',
  setup() {
    const cameraAccess = ref(true);
    const router = useRouter();
    let qrScanner = null;
    const umkm = ref({});
    const umkmPoints = ref(0);
    const imageSrc = require('@/assets/logo.png');
    const showVoucherPopup = ref(false);
    const voucherInfo = ref({});
    const redeemValue = ref(0);
    const apiUrl = process.env.VUE_APP_API_URL;

    const requestCameraAccess = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        cameraAccess.value = true;
      } catch (err) {
        cameraAccess.value = false;
        console.error('Camera access denied:', err);
      }
    };

    const startQRScan = async () => {
      await requestCameraAccess();
      if (!cameraAccess.value) {
        console.error('No camera access');
        return;
      }

      const videoElem = document.getElementById('qr-video');
      videoElem.style.height = '400px';
      qrScanner = new QrScanner(videoElem, (result) => {
        qrScanner.stop();
        const qrData = parseQRCodeData(result.data);
        voucherInfo.value = qrData; // Set voucher info for the popup
        showVoucherPopup.value = true; // Show the popup
      }, {
        returnDetailedScanResult: true,
        preferredCamera: 'environment'
      });

      qrScanner.start().catch((error) => {
        console.error('QR scanner initialization error:', error);
      });
    };

    const parseQRCodeData = (decodedText) => {
      const data = decodedText.split(',').reduce((acc, item) => {
        const [key, value] = item.split(':');
        acc[key] = value;
        return acc;
      }, {});
      return data;
    };

    const redeemVoucher = async () => {
      if (redeemValue.value > 0) {
        try {
          const response = await fetch(apiUrl + "/transaksi_umkm", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id_umkm: umkm.value.id_umkm,
              id_user: voucherInfo.value.id_user,
              code_voucher: voucherInfo.value.code,
              value: redeemValue.value,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            alert(data.message || "Voucher redeemed successfully");
            showVoucherPopup.value = false; // Close the popup
            redeemValue.value = 0; // Reset the input field
          } else {
            const errorData = await response.json();
            alert(errorData.message || "Failed to redeem voucher");
          }
        } catch (error) {
          console.error("Error redeeming voucher:", error);
          alert("Error redeeming voucher");
        }
      } else {
        alert("Tidak boleh nol");
      }
    };

    const fetchUmkmData = async () => {
      const umkmData = JSON.parse(localStorage.getItem('umkm'));
      if (umkmData) {
        try {
          const response = await fetch(`${apiUrl}/umkm/${umkmData.id_umkm}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          umkm.value = data;
          umkmPoints.value = data.point || 0;
        } catch (error) {
          console.error('Fetch error:', error);
          router.push('/umkm'); // Redirect to login if there's an error
        }
      } else {
        router.push('/umkm'); // Redirect to login if no user data found
      }
    };

    const handleLogout = () => {
      localStorage.removeItem('umkm');
      router.push('/umkm');
    };

    onMounted(() => {
      fetchUmkmData();
      if (qrScanner) {
        qrScanner.destroy();
      }
    });

    return {
      umkm,
      umkmPoints,
      imageSrc,
      handleLogout,
      router,
      cameraAccess,
      startQRScan,
      showVoucherPopup,
      voucherInfo,
      redeemValue,
      redeemVoucher
    };
  },
};
</script>
