<template>
  <div class="max-w-lg w-full mx-auto h-screen">
    <h1 class="text-2xl font-bold mb-4">Edit User</h1>
    <form @submit.prevent="handleSubmit">
      <div class="mb-4">
        <label class="label">ID User</label>
        <input v-model="idUser" type="text" class="input input-bordered w-full" disabled />
      </div>
      <div class="mb-4">
        <label class="label">Nama</label>
        <input v-model="nama" type="text" class="input input-bordered w-full" />
      </div>
      <div class="mb-4">
        <label class="label">Nomor HP</label>
        <input v-model="nomorHP" type="text" class="input input-bordered w-full" />
      </div>
      <div class="mb-4 flex gap-4">
        <div class="w-1/2">
          <label class="label">RT</label>
          <input v-model="rt" type="text" class="input input-bordered w-full" />
        </div>
        <div class="w-1/2">
          <label class="label">RW</label>
          <input v-model="rw" type="text" class="input input-bordered w-full" />
        </div>
      </div>
      <div class="mb-4">
        <label class="label">Password</label>
        <input v-model="password" type="text" class="input input-bordered w-full" placeholder="Kosongkan jika tidak diubah">
      </div>
      <div class="flex gap-4">
        <button type="submit" class="btn btn-primary">Simpan</button>
        <button @click="goBack" class="btn btn-secondary">Kembali</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'AdminEditUser',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const idUser = ref('');
    const nama = ref('');
    const nomorHP = ref('');
    const rt = ref('');
    const rw = ref('');
    const password = ref('');  // Leave password empty initially
    let total_point = {};
    const apiUrl = process.env.VUE_APP_API_URL;

    onMounted(async () => {
      try {
        const userId = route.params.id;
        const response = await fetch(`${apiUrl}/user/${userId}`);
        const data = await response.json();

        idUser.value = data.id_user;
        nama.value = data.nama_user;
        nomorHP.value = data.no_hp;
        rt.value = data.rt;
        rw.value = data.rw;
        total_point = data.total_point;

        // Do not set password here, so the input remains empty
      } catch (error) {
        console.error('Error fetching user data:', error);
        alert('Error fetching user data.');
      }
    });

    const handleSubmit = async () => {
      try {
        const updatedData = {
          nama_user: nama.value,
          no_hp: nomorHP.value,
          rt: rt.value,
          rw: rw.value,
          total_point: total_point,
        };

        // Only add password to updatedData if it's filled
        if (password.value) {
          updatedData.password = password.value;
        }

        const response = await fetch(`${apiUrl}/user/${idUser.value}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedData),
        });

        if (response.ok) {
          alert('User berhasil diubah!');
          router.push('/admin/users');
        } else {
          alert('Gagal mengubah user.');
        }
      } catch (error) {
        console.error('Error updating user:', error);
        alert('Error updating user.');
      }
    };

    const goBack = () => {
      router.push('/admin/users');
    };

    return {
      idUser,
      nama,
      nomorHP,
      rt,
      rw,
      password,
      handleSubmit,
      goBack,
    };
  },
};
</script>
