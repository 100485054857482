<template>
  <div class="max-w-lg w-full mx-auto h-screen">
    <h1 class="text-2xl font-bold mb-4">Tambah User</h1>
    <form @submit.prevent="handleSubmit">
      <div class="mb-4">
        <label class="label">Kelurahan</label>
        <select v-model="desa" class="input input-bordered w-full" @change="fetchDesaKode">
          <option disabled value="">Pilih Kelurahan</option>
          <option v-for="desaItem in desaOptions" :key="desaItem.id" :value="desaItem">
            {{ desaItem.nama_desa }}
          </option>
        </select>
      </div>
      <div class="mb-4 flex gap-4">
        <div class="w-1/2">
          <label class="label">RT</label>
          <input v-model="rt" type="text" class="input input-bordered w-full" />
        </div>
        <div class="w-1/2">
          <label class="label">RW</label>
          <input v-model="rw" type="text" class="input input-bordered w-full" />
        </div>
      </div>
      <div class="mb-4">
        <label class="label">Nama</label>
        <input v-model="nama" type="text" class="input input-bordered w-full" />
      </div>
      <div class="mb-4">
        <label class="label">Nomor HP</label>
        <input v-model="nomorHP" type="text" class="input input-bordered w-full" />
      </div>
      <div class="mb-4">
        <label class="label">Password</label>
        <input v-model="password" type="text" class="input input-bordered w-full" />
      </div>
      <div class="flex gap-4">
        <button type="submit" class="btn btn-primary">Simpan</button>
        <button type="button" @click="goBack" class="btn btn-secondary">Kembali</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'AdminTambahUser',
  setup() {
    const desa = ref('');
    const rt = ref('');
    const rw = ref('');
    const nama = ref('');
    const nomorHP = ref('');
    const password = ref('');
    const desaOptions = ref([]);
    const router = useRouter();
    const apiUrl = process.env.VUE_APP_API_URL;

    // Fetch desa options when component is mounted
    onMounted(async () => {
      try {
        const response = await fetch(apiUrl + '/desa');
        const data = await response.json();
        desaOptions.value = data.data.map(desa => ({
          id: desa.id_desa,
          nama_desa: desa.nama_desa,
          kode: desa.kode,
        }));
      } catch (error) {
        console.error('Error fetching desa options:', error);
      }
    });

    const fetchDesaKode = () => {
      // This function can be used to update UI based on selected desa if needed
    };

    const handleSubmit = async () => {
      try {
        const response = await fetch(apiUrl + '/user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password: password.value,
            nama_user: nama.value,
            no_hp: nomorHP.value,
            desa: desa.value.nama_desa,
            rt: rt.value,
            rw: rw.value,
            total_point: 0,
          }),
        });

        if (response.ok) {
          alert('User berhasil ditambahkan!');
          router.push('/admin/users');
        } else {
          alert('Gagal menambahkan user.');
        }
      } catch (error) {
        console.error('Error adding user:', error);
        alert('Error adding user.');
      }
    };

    const goBack = () => {
      router.push('/admin/users');
    };

    return {
      desa,
      rt,
      rw,
      nama,
      nomorHP,
      password,
      desaOptions,
      fetchDesaKode,
      handleSubmit,
      goBack,
    };
  },
};
</script>
