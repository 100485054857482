<template>
  <div class="max-w-lg w-full mx-auto h-screen">
    <h1 class="text-2xl font-bold mb-4">Tambah Kelurahan</h1>
    <form @submit.prevent="handleSubmit" class="space-y-4">
      <div class="form-control">
        <label class="label">
          <span class="label-text">Nama Kelurahan</span>
        </label>
        <input v-model="namaDesa" type="text" class="input input-bordered w-full" placeholder="Nama Kelurahan" />
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Kode Kelurahan</span>
        </label>
        <input v-model="kodeDesa" type="text" class="input input-bordered w-full" placeholder="Kode Kelurahan" />
      </div>
      <div class="flex gap-4">
        <button type="submit" class="btn btn-primary">Simpan</button>
        <button type="button" @click="goBack" class="btn btn-secondary">Kembali</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'AdminTambahDesa',
  setup() {
    const router = useRouter();
    const namaDesa = ref('');
    const kodeDesa = ref('');
    const apiUrl = process.env.VUE_APP_API_URL;

    const handleSubmit = async () => {
      try {
        const response = await fetch(apiUrl+'/desa', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nama_desa: namaDesa.value,
            kode: kodeDesa.value,
          }),
        });

        if (response.ok) {
          // alert('Desa added successfully!');
          router.push('/admin/desa');
        } else {
          alert('Failed to add desa.');
        }
      } catch (error) {
        console.error('Error adding desa:', error);
        alert('Error adding desa.');
      }
    };

    const goBack = () => {
      router.push('/admin/desa');
    };

    return {
      namaDesa,
      kodeDesa,
      handleSubmit,
      goBack,
    };
  },
};
</script>
