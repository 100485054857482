<template>
  <div class="hero bg-base-200 min-h-screen">
    <div class="hero-content flex-col lg:flex-row-reverse">
      <div class="text-center lg:text-left py-4 lg:px-12 max-w-md lg:max-w-xl">
        <h1 class="text-3xl font-bold">Mulai Sekarang!</h1>
        <p class="py-6">
          Setiap sampah yang Anda kumpulkan akan mengumpulkan poin berharga. Tukar poin Anda UMKM di sekitar Anda dan nikmati manfaat langsung dari partisipasi Anda.
        </p>
        <p>Login sebagai UMKM? <u><a href="/umkm">Klik disini</a></u></p>
      </div>
      <div class="card bg-base-100 w-full max-w-sm shrink-0 shadow-2xl">
        <div class="flex-1 ml-4">
          <img :src="imageSrc" alt="Bank Sampah Rejeki" class="max-h-20 mx-auto mt-8">
        </div>
        <form @submit.prevent="handleLogin" class="card-body">
          <div class="form-control">
            <label class="label">
              <span class="label-text">ID User</span>
            </label>
            <input id="username" v-model="id_user" type="text" placeholder="SAXXX1" class="input input-bordered" required />
          </div>
          <div class="form-control relative">
            <label class="label">
              <span class="label-text">Password</span>
            </label>
            <input :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="password" class="input input-bordered pr-10" required />
            <button type="button" @click="togglePasswordVisibility" class="absolute right-0 bottom-3 px-4 text-gray-500">
              <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
            </button>
          </div>
          <div v-if="message" :class="isError ? 'alert alert-error' : 'alert alert-success'" role="alert">
            <svg v-if="isError" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0 stroke-current" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0 stroke-current" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <span>{{ message }}</span>
          </div>
          <div class="form-control mt-6">
            <button class="btn btn-primary">Masuk</button>
          </div>
          <label class="label">
            <span class="label-text mx-auto">atau</span>
          </label>
          <button type="button" class="btn" @click="startQRScan">
            <i class="fa-solid fa-qrcode"></i> Masuk dengan Scan
          </button>
        </form>
        <div v-if="!cameraAccess" class="mt-4 p-4 bg-red-100 border border-red-500 text-red-700">
          <p>Untuk menggunakan fitur pemindaian QR, mohon izinkan akses kamera di pengaturan browser Anda.</p>
        </div>
        <video id="qr-video" style="width: 100%; height: 0px;"></video>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import QrScanner from 'qr-scanner';

export default {
  name: 'LoginUser',
  data() {
    return {
      imageSrc: require('@/assets/logo.png'),
      showPassword: false, // State to manage password visibility
    };
  },
  setup() {
    const id_user = ref('');
    const password = ref('');
    const isError = ref(false);
    const message = ref('');
    const cameraAccess = ref(true);
    const router = useRouter();
    let qrScanner = null;
    const apiUrl = process.env.VUE_APP_API_URL;

    const requestCameraAccess = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        cameraAccess.value = true;
      } catch (err) {
        cameraAccess.value = false;
        console.error('Camera access denied:', err);
      }
    };

    const handleLogin = async () => {
      try {
        const response = await fetch(apiUrl+'/auth/user/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id_user: id_user.value, password: password.value }),
        });

        const data = await response.json();
        if (response.ok) {
          localStorage.setItem('user', JSON.stringify(data.user));
          isError.value = false;
          message.value = 'Login successful!';
          router.push('/dashboard');
        } else {
          isError.value = true;
          message.value = data.message;
        }
      } catch (error) {
        console.error('Login error:', error);
        isError.value = true;
        message.value = 'An error occurred. Please try again.';
      }
    };

    const startQRScan = async () => {
      await requestCameraAccess();
      if (!cameraAccess.value) {
        console.error('No camera access');
        return;
      }

      const videoElem = document.getElementById('qr-video');
      videoElem.style.height = '400px';
      qrScanner = new QrScanner(videoElem, (result) => {
        qrScanner.stop();
        const qrData = parseQRCodeData(result.data);
        performLogin(qrData.id_user, qrData.password);
      }, {
        returnDetailedScanResult: true,
        preferredCamera: 'environment'
      });

      qrScanner.start().catch((error) => {
        console.error('QR scanner initialization error:', error);
      });
    };

    const parseQRCodeData = (decodedText) => {
      const data = decodedText.split(',').reduce((acc, item) => {
        const [key, value] = item.split(':');
        acc[key] = value;
        return acc;
      }, {});
      return data;
    };

    const performLogin = async (id_user, password) => {
      try {
        const response = await fetch(apiUrl+'/auth/user/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id_user, password }),
        });

        const data = await response.json();
        if (response.ok) {
          localStorage.setItem('user', JSON.stringify(data.user));
          isError.value = false;
          message.value = 'Login successful!';
          router.push('/dashboard');
        } else {
          isError.value = true;
          message.value = data.message;
        }
      } catch (error) {
        console.error('Login error:', error);
        isError.value = true;
        message.value = 'An error occurred. Please try again.';
      }
    };

    onUnmounted(() => {
      if (qrScanner) {
        qrScanner.destroy();
      }
    });

    return {
      id_user,
      password,
      isError,
      message,
      cameraAccess,
      handleLogin,
      startQRScan,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
#qr-video {
  width: 100%;
  height: 400px; /* Adjust as needed */
  display: block;
}
@media (max-width: 768px) {
  #qr-video {
    height: 300px; /* Adjust for mobile screens */
  }
}
</style>
