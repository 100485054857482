<template>
  <div class="drawer lg:drawer-open">
    <input id="my-drawer-2" type="checkbox" class="drawer-toggle" />
    <div class="drawer-content flex flex-col pt-8 lg:pt-16 px-8 lg:px-24 bg-base-100">
      <!-- Page content here -->
      
      <div class="navbar p-0 lg:hidden mb-4">
        <div class="flex-none">
          <label for="my-drawer-2" class="btn btn-square btn-ghost border border-base-300 rounded">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="inline-block h-5 w-5 stroke-current">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </label>
        </div>
        <div class="flex-1 ml-4">
          <img :src="imageSrc" alt="Bank Sampah Rejeki" class="max-h-16">
        </div>
        
      </div>

      <router-view></router-view>
    </div>
    <div class="drawer-side bg-base">
      <label for="my-drawer-2" aria-label="close sidebar" class="drawer-overlay"></label>
      <ul class="menu bg-base-300 text-base-content min-h-full w-72 p-4">
        <!-- Sidebar content here -->
        <div class="flex items-center my-4">
          <img :src="imageSrc" alt="Bank Sampah Rejeki" class="max-h-16">
        </div>
        <li><router-link to="/admin/users" @click="closeSidebar">Warga</router-link></li>
        <li><router-link to="/admin/desa" @click="closeSidebar">Kelurahan</router-link></li>
        <li><router-link to="/admin/umkm" @click="closeSidebar">UMKM</router-link></li>
        <li><router-link to="/admin/sampah" @click="closeSidebar">Jenis Sampah</router-link></li>
        <li><router-link to="/admin/transaksi-sampah" @click="closeSidebar">Transaksi Sampah</router-link></li>
        <li><router-link to="/admin/transaksi-umkm" @click="closeSidebar">Transaksi UMKM</router-link></li>
        <li class="mt-6"><button @click="showConfirm = true">Log out</button></li>
      </ul>
    </div>

    <div v-if="showConfirm" role="alert" class="alert fixed top-0 left-0 right-0 mt-4 mx-auto max-w-lg border-0 bg-transparent animate__animated animate__bounceInDown">
      <div class="flex items-center p-4 rounded-lg shadow-lg bg-white text-black dark:bg-dark-bg dark:text-dark-text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="stroke-info h-6 w-6 shrink-0 mr-2">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
        <span class="flex-grow mr-4">Yakin ingin keluar?</span>
        <div class="flex gap-2">
          <button @click="cancelLogout" class="btn btn-sm">Batal</button>
          <button @click="logout" class="btn btn-sm btn-error">Ya</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminDashboard',
  data() {
    return {
      imageSrc: require('@/assets/logo.png'),
      showConfirm: false
    };
  },
  methods: {
    // Fungsi untuk logout
    logout() {
      localStorage.removeItem('adminLoggedIn'); // Remove login status
      this.$router.push('/admin/login');
    },
    cancelLogout() {
      this.showConfirm = false;
    },
    closeSidebar() {
      const drawerToggle = document.getElementById('my-drawer-2');
      if (drawerToggle) {
        drawerToggle.checked = false;
      }
    }
  }
};
</script>

<style scoped>
.drawer-content {
  width: 100%;
}
.drawer-side {
  z-index: 2; /* Ensure sidebar is above table header */
}
.alert {
  z-index: 3; /* Ensure alert is above table header */
}
</style>
